@font-face {
  font-family: "Muli";
  src: url("./fonts/Muli.ttf");
}

@font-face {
  font-family: "Muli";
  font-style: italic;
  src: url("./fonts/Muli-Italic.ttf");
}

@font-face {
  font-family: "Muli";
  font-weight: bold;
  src: url("./fonts/Muli-Bold.ttf");
}

@font-face {
  font-family: "Muli";
  font-weight: bold;
  font-style: italic;
  src: url("./fonts/Muli-BoldItalic.ttf");
}

@font-face {
  font-family: "Muli";
  font-weight: 100;
  src: url("./fonts/Muli-ExtraLight.ttf");
}

@font-face {
  font-family: "Muli";
  font-weight: 100;
  font-style: italic;
  src: url("./fonts/Muli-ExtraLightItalic.ttf");
}

@font-face {
  font-family: "Muli";
  font-weight: 300;
  src: url("./fonts/Muli-Light.ttf");
}

@font-face {
  font-family: "Muli";
  font-weight: 300;
  font-style: italic;
  src: url("./fonts/Muli-LightItalic.ttf");
}

@font-face {
  font-family: "Muli";
  font-weight: 500;
  src: url("./fonts/Muli-SemiBold.ttf");
}

@font-face {
  font-family: "Muli";
  font-weight: 500;
  font-style: italic;
  src: url("./fonts/Muli-Semi-BoldItalic.ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
